import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    StackDivider
} from "@chakra-ui/react"
import React, {useState} from "react"
import { Map, Marker } from "pigeon-maps"
import { Frame } from "../../components/wrappers/Frame"

function MapDemo() {
    const [[lat, lng], setValue] = useState([29.97648, 31.131302])

    return (
        <Frame>
            <Stack
                direction={["column", null, "column"]}
                spacing={8}
                borderColor="inherit"
                divider={<StackDivider />}
            >
                <Box>
                    <FormControl>
                        <FormLabel value={lat} htmlFor="lat">
                            Latitude
                        </FormLabel>
                        <Input
                            id="lat"
                            value={lat}
                            onChange={e =>
                                setValue([
                                    Number.parseFloat(e.target.value),
                                    lng,
                                ])
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel value={lng} htmlFor="lng">
                            longitude
                        </FormLabel>
                        <Input
                            id="lng"
                            value={lng}
                            onChange={e =>
                                setValue([
                                    lat,
                                    Number.parseFloat(e.target.value),
                                ])
                            }
                        />
                    </FormControl>
                </Box>
                <Box h="md" w="full" maxW="calc(100vw - 3.1rem)" sx={{
                    'img': {
                        width: 'auto',
                        height: 'auto',
                    }
                }}>
                    <Map
                        dprs={[1,2]}
                        defaultCenter={[lat, lng]}
                        defaultZoom={4}
                        onClick={({ latLng }) =>
                            setValue(latLng as [number, number])
                        }
                    >
                        <Marker anchor={[lat, lng]} />
                    </Map>
                </Box>
            </Stack>
        </Frame>
    )
}

export { MapDemo }

